/*
 * @Descripttion:
 * @FilePath: /azg/src/utils/clipboard.js
 * @Author: 张兴业
 * @Date: 2020-09-07 19:01:50
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-04-19 20:46:11
 */
import Vue from "vue";
import Clipboard from "clipboard";

function clipboardSuccess() {
  Vue.prototype.$message({
    message: "复制成功！",
    type: "success",
    duration: 1500
  });
}

function clipboardError() {
  Vue.prototype.$message({
    message: "复制失败！",
    type: "error"
  });
}

export default function handleClipboard(text, event) {
  const clipboard = new Clipboard(event.target, {
    text: () => text
  });
  clipboard.on("success", () => {
    clipboardSuccess();
    clipboard.destroy();
  });
  clipboard.on("error", () => {
    clipboardError();
    clipboard.destroy();
  });
  clipboard.onClick(event);
}
