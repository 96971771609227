<!--
 * @Descripttion: 地推扫码 注册分销店主
 * @FilePath: /azg/src/views/ditui/register/index.vue
 * @Author: 张兴业
 * @Date: 2021-04-12 10:04:29
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-11-19 12:50:46
-->
<template>
  <div class="login">
    <!-- <nav-bar @back="back" /> -->
    <!-- <page-title :title="isPasswdLogin ? '密码登录' : '手机号登录'" /> -->
    <div class="top_bg">
      <img src="@/assets/img/ditui/top_bg.png" alt="" class="top_bg_img" />
      <div class="center_logo_back">
        <img :src="logoPath" alt="" class="center_logo" />
        <div>{{ topTitle }}</div>
      </div>
      <div class="top_download">
        <img :src="logoPath" alt="" class="left_logo" />
        <div class="middle">
          <div>{{ `下载az${downTitle}APP` }}</div>
          <div class="inviteCode_bg">
            <span class="inviteCode_text">{{ inviteCode }}</span>
            <span class="copy" @click="onCopy">复制邀请码</span>
          </div>
        </div>
        <van-button type="info" class="right" @click="goDownload">
          立即下载
        </van-button>
      </div>
    </div>
    <div class="login_con">
      <van-form ref="login" @submit="onSubmit">
        <!-- 邀请码 -->
        <invite-code-field v-model="inviteCode" />
        <!-- 店铺名称 -->
        <!-- <store-name-field v-model="shopName" style="margin-top: 12px;" /> -->
        <!-- <address-city
          ref="addressArea"
          v-model="area"
          style="margin-top: 12px;"
        /> -->
        <!-- 店铺地址 -->
        <!-- <address-field
          ref="address"
          v-model="address"
          style="margin-top: 12px;"
        /> -->
        <!-- 电子邮箱 -->
        <!-- <eamil-field v-model="email" style="margin-top: 12px;" /> -->
        <!-- 手机号 -->
        <phone-field v-model="phone" style="margin-top: 12px;" />
        <!-- 验证码 -->
        <code-field
          v-if="!isPasswdLogin"
          v-model="verifyCode"
          :phone="checkPhone"
          style="margin-top: 12px;"
          @senderCode="senderCode"
          @verifyCodeChange="verifyCodeChange"
        />
        <!-- 密码 -->
        <!-- <password-field v-if="isPasswdLogin" v-model="password" /> -->
        <!-- 协议 -->
        <div class="actions">
          <!-- 登录按钮 -->
          <van-button
            :loading="loading"
            block
            type="info"
            native-type="submit"
            class="login_submit"
            :disabled="disabledBtn"
          >
            注册
          </van-button>
        </div>
        <agreement v-model="agree" />
      </van-form>
      <div v-if="errorMsg" class="login_error">
        {{ errorMsg }}
      </div>
      <!-- <div class="login_switch">
        <span @click="swichLoginMethod">
          {{ isPasswdLogin ? "验证码登录" : "密码登录" }}
        </span>
        <span v-if="isPasswdLogin" class="forgot" @click="goForgot">
          忘记密码
        </span>
      </div> -->
      <!-- <div class="wechat_con">
        <wechat-button @wechat="wechat" />
      </div> -->
    </div>
  </div>
</template>

<script>
// import wechat from "@/utils/wechat";
import { verifyPhoneWithNoAreaCode } from "@/utils/pattern.js";
import clip from "@/utils/clipboard";

// const NavBar = () => import("./components/NavBar");
// const PageTitle = () => import("./components/PageTitle");
const PhoneField = () => import("./components/PhoneField");
const CodeField = () => import("./components/CodeField");
const Agreement = () => import("./components/Agreement");
const InviteCodeField = () => import("./components/InviteCodeField");
// const StoreNameField = () => import("./components/StoreNameField");
// const AddressCity = () => import("./components/AddressCity");
// const AddressField = () => import("./components/AddressField");
// const EamilField = () => import("./components/EamilField");
// const WechatButton = () => import("./components/WechatButton");
// const PasswordField = () => import("./components/PasswordField");

export default {
  components: {
    // PageTitle,
    PhoneField,
    CodeField,
    Agreement,
    InviteCodeField,
    // StoreNameField,
    // AddressField,
    // AddressCity
    // EamilField
  },
  data() {
    return {
      inviteCode: "",
      shopName: "",
      area: "",
      address: "",
      redirect: undefined,
      otherQuery: {},
      prefix: "+86",
      phone: "",
      email: "",
      verifyCode: "",
      agree: "0",
      errorMsg: "",
      isPasswdLogin: false,
      password: "",
      path: "",
      addressData: [],
      loading: false,
      type: "0", // 0 精品通， 1 火龙购
    };
  },
  computed: {
    disabledBtn() {
      // console.log(verifyPhoneWithNoAreaCode(this.phone));
      if (
        this.agree === "1" &&
        verifyPhoneWithNoAreaCode(this.phone) &&
        // (this.email ? verifyEmail(this.email) : true) &&
        this.inviteCode &&
        this.verifyCode &&
        this.verifyCode.length === 6
        // this.shopName &&
        // this.area &&
        // this.address
      ) {
        return false;
      }
      return true;
    },
    checkPhone() {
      return verifyPhoneWithNoAreaCode(this.phone);
    },
    topTitle() {
      return this.type === "0" ? "精品通" : "火龙购";
    },
    downTitle() {
      return this.type === "0" ? "精品通" : "火龙购";
    },
    logoPath() {
      return this.type === "0"
        ? require("@/assets/img/ditui/logo.png")
        : require("@/assets/download/retail_app.png");
    },
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.inviteCode = this.$route.query.inviteCode;
    this.type = this.$route.query.type ?? "0";
    /** 是否要判断guid相同 保证是同一次发起的，暂时未想好 */
    // const arr = window.location.href.split("#");
    // if (arr.length > 1) {
    //   const guid = arr[1];
    //   console.log(guid);
    // }
    // wechatLogin 微信登录
    // const query = this.$route.query;
    // if (query.code) {
    //   this.$store
    //     .dispatch("login/wechatLogin", {
    //       params: {
    //         clientCode: query.code
    //       }
    //     })
    //     .then(() => {
    //       this.$router.push({
    //         path: this.redirect || "/",
    //         query: this.otherQuery
    //       });
    //       // console.log(res, "微信登录返回res");
    //       // this.$router.push({
    //       //   path: this.redirect || "/",
    //       //   query: this.otherQuery
    //       // });
    //     })
    //     .catch(err => {
    //       this.errorMsg = err.message;
    //     });
    // }
  },
  methods: {
    back() {
      this.$router.back();
    },
    onCopy(e) {
      clip(this.$route.query.inviteCode, e);
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    // 获取微信登录code
    wechat() {
      // wechat();
    },
    prefixChange(value) {
      this.prefix = value;
    },
    // 验证码/密码登录切换
    swichLoginMethod() {
      this.isPasswdLogin = !this.isPasswdLogin;
    },
    onSubmit2() {
      this.$router.push(
        "/ditui/register/confirm?phone=13593420516&code=80a3de72"
      );
    },
    onSubmit(values) {
      // console.log(values, this.phone, this.agree);
      if (this.loading) {
        return;
      }
      this.loading = true;
      // if (!this.shopName) {
      //   this.errorMsg = "请输入店铺名称";
      //   return;
      // }
      // if (!this.area || !this.$refs.addressArea.districtId) {
      //   this.errorMsg = "请选择店铺区域";
      //   return;
      // }
      // if (!this.address) {
      //   this.errorMsg = "请输入店铺详细地址";
      //   return;
      // }
      // if (values.email && !verifyEmail(values.email)) {
      //   this.errorMsg = "邮箱格式不正确";
      //   return;
      // }
      if (!verifyPhoneWithNoAreaCode(values.phone)) {
        this.errorMsg = "手机号码格式不正确";
        return;
      }
      // console.log(this.$refs.address);
      const kind = this.type === "0" ? "StoreDsRegister" : "StoreRegister";
      const data = {
        // storeDsName: this.shopName,
        phone: this.phone,
        code: this.verifyCode,
        kind: kind,
        inviteCode: this.inviteCode,
        // address: this.area + this.address,
        // districtId: this.$refs.addressArea.districtId,
        // email: this.email
      };
      // console.log(this.isPasswdLogin, "this.isPasswdLogin");
      const url =
        this.type === "0"
          ? "/api/front/storeDs/storeDsRegister"
          : "/api/front/store/storeRegister";
      this.$request(url, {
        method: "POST",
        data,
      })
        .then((res) => {
          this.loading = false;
          if (res.code === 1) {
            const url = `/api/front/distributorDs/findOnlineStoreDsInfoByCodeAndPhone?phone=${this.phone}&invitationCode=${this.inviteCode}`;
            localStorage.setItem("RegisterToken", res.data.access_token);
            this.$request(url, {
              headers: { Authorization: `Bearer ${res.data.access_token}` },
            })
              .then((resInfo) => {
                if (resInfo.code === 1) {
                  if (resInfo.data.type == 2) {
                    localStorage.setItem(
                      "RegisterInfo",
                      JSON.stringify({
                        ...resInfo.data.storeDs,
                        phone: this.phone,
                        inviteCode: this.inviteCode,
                      })
                    );
                    this.$router.push(`/ditui/register/confirm`);
                  } else {
                    localStorage.removeItem("RegisterInfo");
                    localStorage.removeItem("RegisterToken");
                    this.goDownload();
                  }
                }
              })
              .catch(() => {});
          }
          if (res.code == 1100 && this.type == "1") {
            this.$message({
              message: res.message,
              type: "none",
              customClass: "zidingyiel_message",
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    goForgot() {
      this.$router.push("/forgot");
    },
    // 验证码
    verifyCodeChange(value) {
      this.verifyCode = value;
    },
    // 发送验证码
    senderCode(callback) {
      if (this.sending) {
        return;
      }
      const kind = this.type === "0" ? "StoreDsRegister" : "StoreRegister";
      const line = this.type === "0" ? 2 : 1;
      const data = {
        phone: this.phone,
        kind: kind,
        line: line,
      };
      const url = "/api/front/user/sendSms";
      this.sending = true;
      this.$request(url, {
        method: "POST",
        data,
      })
        .then((res) => {
          this.sending = false;
          if (res.code === 1) {
            this.$message({
              message: "验证码已发送到您的手机",
              type: "success",
            });
            callback();
          }
        })
        .catch(() => {
          this.sending = false;
        });
    },
    goDownload() {
      this.$router.push(`/download/app/distributor?type=${this.type}`);
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: left;
  overflow: auto;

  .login_close {
    width: 24px;
    margin: 11px 0 0 24px;
  }
  .login_title {
    margin-top: 30px;
    line-height: 33px;
  }

  .top_bg {
    position: relative;
    .top_bg_img {
      width: 100%;
    }

    .top_download {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 42px;
      padding: 12px;
      display: flex;
      color: white;

      img {
        width: 42px;
        height: 42px;
      }

      .middle {
        margin: auto 12px;
        width: calc(100% - 154px);
        font-size: 12px;
        line-height: 21px;

        .inviteCode_bg {
          display: flex;
          .inviteCode_text {
            display: block;
            max-width: 100px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin: auto 0;
          }
        }

        .copy {
          border: 1px solid #ffffff;
          border-radius: 12px;
          padding: 2px 5px;
          margin-left: 8px;
          cursor: pointer;
          word-break: normal;
        }
      }

      .right {
        margin: auto 0;
        width: 88px;
        background: white;
        border: none;
        color: black;
        height: 32px;
        border-radius: 16px;
        font-size: 13px;
        font-weight: normal;
      }
    }

    .center_logo_back {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 70px;
      height: 70px;
      transform: translate(-50%, -50%);
      font-size: 14px;
      text-align: center;

      .center_logo {
        // position: absolute;
        // left: 50%;
        // top: 50%;
        width: 70px;
        height: 70px;
        margin-bottom: 5px;
        // transform: translate(-50%, -50%);
      }
    }
  }

  .login_con {
    margin: 0 38px 72px 38px;
  }

  .actions {
    margin-top: 12px;

    .login_submit {
      background: linear-gradient(135deg, #434343 100%, #000000 100%);
      border: none;
      font-size: 14px;
      height: 48px;
      line-height: 48px;
      border-radius: 13px;
      font-weight: 500;
    }
  }

  .login_error {
    padding: 4px;
    color: #ff0000;
    font-size: 12px;
    text-align: center;
  }

  .login_switch {
    padding: 10px 0;
    font-size: 12px;
    font-weight: 400;

    span {
      cursor: pointer;
    }

    .forgot {
      float: right;
    }
  }

  .wechat_con {
    margin-top: 80px;
  }

  .van-cell {
    padding: 10px 0;
  }
}
</style>
